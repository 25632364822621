
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import * as Yup from 'yup';
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {TAG_COLOR} from "@/core/config/Constant";
import TagService from "@/core/services/TagService";

export default defineComponent({
  name: "TagForm",
  components: {BaseSelect, FormErrorMessage, BaseForm, Field},
  props: {
    id: {},
    tag: {type: Object, required: true},
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);

    const model = ref(props.tag);
    watch(() => props.tag, cb => {
      model.value = cb;
    })
    const validate = Yup.object().shape({
      name: Yup.string().required().max(255).label('Name')
    })
    return {
      submitting,
      validate,
      model,
      TAG_COLOR
    }
  },
  methods: {
    formSubmit() {
      this.submitting = true;
      if (this.id) {
        TagService.update(this.id, this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        TagService.create(this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false;
        })
      }
    }
  }
})
