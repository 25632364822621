import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "menu-item px-3" }
const _hoisted_2 = { class: "card card-flush" }
const _hoisted_3 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_4 = { class: "form-check form-switch form-check-custom form-check-solid" }
const _hoisted_5 = ["name", "value", "checked", "disabled"]
const _hoisted_6 = { class: "menu-item px-3" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TagForm = _resolveComponent("TagForm")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_ProcessType = _resolveComponent("ProcessType")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!
  const _component_Entities = _resolveComponent("Entities")!
  const _directive_is_admin = _resolveDirective("is-admin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseModal, {
      id: "formTag",
      name: _ctx.state.tagForm,
      ref: "formTagRef"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TagForm, {
          tag: _ctx.state.tag,
          id: _ctx.state.id,
          onSaved: _ctx.onSaved
        }, null, 8, ["tag", "id", "onSaved"])
      ]),
      _: 1
    }, 8, ["name"]),
    _createVNode(_component_Entities, {
      "enable-filter": false,
      "view-card": false,
      "view-table": true,
      label: "Tags",
      items: _ctx.page.total,
      "display-view": false
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_QuickAction, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: "menu-link px-3",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAdd && _ctx.onAdd(...args)))
              }, "New Tag")
            ])), [
              [_directive_is_admin]
            ])
          ]),
          _: 1
        })
      ]),
      table: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.page.data)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_KtDatatable, {
                  "table-data": _ctx.page.data.data,
                  "table-header": _ctx.headers,
                  "enable-items-per-page-dropdown": true,
                  "current-page": _ctx.filter.paginationPage,
                  "rows-per-page": _ctx.filter.paginationSize,
                  onItemsPerPageChange: _ctx.handleUpdateSize,
                  onCurrentChange: _ctx.handleUpdatePage,
                  total: _ctx.page.total
                }, {
                  "cell-name": _withCtx(({ row: data }) => [
                    _createElementVNode("span", {
                      class: "badge badge-white me-3",
                      style: _normalizeStyle({background: _ctx.buildColor(data.color), 'color': 'white'})
                    }, _toDisplayString(data.name), 5)
                  ]),
                  "cell-entity": _withCtx(({ row: data }) => [
                    _createTextVNode(_toDisplayString(data.objectType) + " ", 1),
                    (data.objectType ==='PROCESS')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (data.processType)
                            ? (_openBlock(), _createBlock(_component_ProcessType, {
                                key: 0,
                                type: data.processType
                              }, null, 8, ["type"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  "cell-active": _withCtx(({ row: data }) => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("input", {
                        class: "form-check-input me-3",
                        name: data.id,
                        type: "checkbox",
                        value: data.id,
                        checked: data.active,
                        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
                        disabled: _ctx.loading || !_ctx.isAdmin()
                      }, null, 40, _hoisted_5)
                    ])
                  ]),
                  "cell-actions": _withCtx(({ row: data }) => [
                    _createVNode(_component_QuickAction, null, {
                      default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3",
                            onClick: ($event: any) => (_ctx.onEdit(data))
                          }, "Edit", 8, _hoisted_7),
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3 text-danger",
                            onClick: ($event: any) => (_ctx.onDelete(data.id))
                          }, "Delete", 8, _hoisted_8)
                        ])), [
                          [_directive_is_admin]
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ], 64))
}