
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import {LoadFilterObjects, userRoleCheck} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import Swal from "sweetalert2";
import TagService from "@/core/services/TagService";
import {TAG_COLOR} from "@/core/config/Constant";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import TagForm from "@/views/tag/TagForm.vue";
import ProcessType from "@/views/process/ProcessType.vue";

export default defineComponent({
  name: "List",
  components: {ProcessType, BaseModal, TagForm, KtDatatable, QuickAction, Entities},
  setup() {
    setCurrentPageBreadcrumbsWithParams('Tags',
      [
        {link: false, router: '', text: 'Tags'}
      ]
    )
    const objectFilter = ref({});
    const loading = ref(false);
    const page = computed(() => store.state.TagModule.page);
    const headers = [
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Entity",
        key: "entity",
        sortable: true,
      },
      {
        name: "Active",
        key: "active",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]

    const state = ref({tagForm: 'Add Tag', id: '', tag: {name: '', color: 'green', objectType: ''}})
    return {
      ...LoadFilterObjects(Actions.LOAD_TAGS, objectFilter.value),
      objectFilter,
      page,
      headers,
      state,
      ...userRoleCheck(),
      loading,
    }
  },
  methods: {
    buildColor(color) {
      const c = TAG_COLOR.find(item => item.name === color);
      return c ? c.hex : '';
    },
    onAdd() {
      this.state = {tagForm: 'Add Tag', id: '', tag: {name: '', color: 'green', objectType: 'POLICY'}}
      const modal = this.$refs.formTagRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      const modal = this.$refs.formTagRef as typeof BaseModal;
      modal.hideBaseModal();
      this.paginationLoad();
    },
    onEdit(data) {
      this.state = {
        tagForm: 'Edit Tag',
        id: data.id,
        tag: {name: data.name, color: data.color, objectType: data.objectType}
      }
      const modal = this.$refs.formTagRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TagService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    },
    onChange($event) {
      this.loading= true
      TagService.updateStatus($event.target.value).finally(() => {
        this.loading = false
      })
    }
  }
})
